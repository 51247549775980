import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import $ from 'jquery';
import React, { useState } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import ReactTooltip from 'react-tooltip';
import '../../App.css';
import MinMap from '../map/minMap';

export default function New() {
  const [content, setContent] = useState('');

  const backToTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  };

  const goToRef = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('#refs').offset().top - 70,
      },
      500
    );
  };

  const LANGUAGES = [
    {
      lang: 'English',
      code: 'ENG',
    },
    {
      lang: 'Polish',
      code: 'PL',
    },
    {
      lang: 'Turkish',
      code: 'TR',
    },
    {
      lang: 'Slovenian',
      code: 'SL',
    },
    {
      lang: 'Finnish',
      code: 'FI',
    },

    {
      lang: 'Croatian',
      code: 'HR',
    },
    {
      lang: 'Russian',
      code: 'RU',
    },
    {
      lang: 'Romanian',
      code: 'RO',
    },
    {
      lang: 'Dutch',
      code: 'NL',
    },
    {
      lang: 'German',
      code: 'DE',
    },
    {
      lang: 'Estonian',
      code: 'ET',
    },
    {
      lang: 'Urdu',
      code: 'URD',
    },
    {
      lang: 'Bahasa-Malaysian',
      code: 'MS',
    },
    {
      lang: 'Italian',
      code: 'IT',
    },
    {
      lang: 'French',
      code: 'FR',
    },
    {
      lang: 'Czech',
      code: 'CS',
    },
    {
      lang: 'Slovak',
      code: 'SK',
    },
    {
      lang: 'Portuguese',
      code: 'PT',
    },
    {
      lang: 'Korean',
      code: 'KO',
    },
    {
      lang: 'Ukrainian',
      code: 'UK',
    },
    {
      lang: 'Thai',
      code: 'TH',
    },
    {
      lang: 'Japanese',
      code: 'JA',
    },
    {
      lang: 'Arabic',
      code: 'AR',
    },
    {
      lang: 'Bosnian',
      code: 'BS',
    },
    {
      lang: 'Hebrew',
      code: 'HE',
    },
    {
      lang: 'Spanish',
      code: 'ES-ES',
    },
    {
      lang: 'Spanish (Latin America)',
      code: 'ES',
    },
    {
      lang: 'Bulgarian',
      code: 'BG',
    },
    {
      lang: 'Hungarian',
      code: 'HU',
    },
    {
      lang: 'Greek',
      code: 'EL',
    },
    {
      lang: 'Serbian',
      code: 'SR',
    },
    {
      lang: 'Swedish',
      code: 'SV',
    },
    {
      lang: 'Macedonian',
      code: 'MK',
    },
    {
      lang: 'Bangla',
      code: 'BN',
    },
    {
      lang: 'Brazilian Portuguese',
      code: 'PT-BR',
    },
    {
      lang: 'Norwegian',
      code: 'NO',
    },
    {
      lang: 'Georgian',
      code: 'KAT',
    },
    {
      lang: 'Chinese (Traditional)',
      code: 'ZH-T',
    },
    {
      lang: 'Chinese (Simplified)',
      code: 'ZH-S',
    },
    {
      lang: 'Lithuanian',
      code: 'LT',
    },
    {
      lang: 'Mongolian',
      code: 'MN',
    },
    {
      lang: 'Swahili',
      code: 'SW',
    },
    {
      lang: 'Farsi',
      code: '',
    },
  ];

  const sortList = (a, b) => {
    if (a.lang < b.lang) {
      return -1;
    }
    if (a.lang > b.lang) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="App">
      <Container maxWidth="md">
        <Box pt={5} pb={3}>
          <img className="gif" src="/logo.jpg" width={200} />
          <Box>
            <Link href="/join">
              <Button variant="contained" size="large" color="primary">
                JOIN TEAM
              </Button>
            </Link>
          </Box>
          <Typography variant="h4" className="sectionName">
            <b>Large-scale study</b> <hr className="headerDivider" />
          </Typography>
        </Box>
        <Box pt={0} pb={2} display="flex" justifyContent="flex-start" bgcolor="background.paper">
          <LinkScroll to="media" color="inherit" spy={true} smooth={true} offset={-70}>
            <Button variant="contained" size="large" color="primary" startIcon={<CampaignIcon />}>
              Results dissemination
            </Button>
          </LinkScroll>
        </Box>
        <Typography variant="h5" className="description" align="justify">
          In February 2021, we have started forming an international team of researchers who were interested in conducting cross-cultural studies.
          Recruitment of new team members (in total, <b>404 scholars</b>) lasted a month.
        </Typography>
        <Box pt={3}>
          <Typography variant="h5" className="description" align="center">
            <img className="gif" src="/STUDY_gif.gif" />
          </Typography>
        </Box>

        <Box pt={5}>
          <Box pt={5} pb={3}>
            <Typography variant="h4" className="sectionName">
              <b>In our project, we focused on four topics:</b>
              <hr className="headerDivider" />
            </Typography>
          </Box>
          <Typography className="description" variant="h5" align="left">
            <Box py={3}>
              <ul>
                <li>Physical attractiveness</li>
                <li>Dyadic relationships</li>
                <li>Mate attraction</li>
                <li>Perceptions of traditional dances</li>
              </ul>
            </Box>
            In March, we started forward-back translation of the questionnaire. <br /> Thanks to the efforts of our excellent scholars, we have
            managed to translate the survey into <b>43 languages</b>, including:
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {LANGUAGES.sort(sortList).map((language) => {
              return (
                <span className="single-lang" key={language.lang}>
                  {language.lang}
                </span>
              );
            })}
          </Box>
        </Box>

        <Box pt={4} className="joinUsButton" bgcolor="background.paper">
          <Link href="/team">
            <Button variant="contained" size="large" color="primary" startIcon={<PeopleIcon />}>
              For team members
            </Button>
          </Link>
        </Box>

        <Box pt={5}>
          <Typography className="description" variant="h5" align="left">
            Between April and August 2021 we recruited participants. Intensified data collection resulted in drawing the attention of{' '}
            <b className="lg">118 715 individuals</b> from <b className="lg">175 countries</b> and territories.
          </Typography>
        </Box>
        <br />
        <Box>
          <Typography className="description" variant="h5" align="center">
            In total, there are 97 countries with more than 30 participants:
          </Typography>
        </Box>

        <Box className="map">
          <Box>
            <Box pt={1} pb={1}>
              <hr className="mapDivider" />
            </Box>
          </Box>
          <MinMap setTooltipContent={setContent} />
          <ReactTooltip>{content}</ReactTooltip>

          <Box sm={12} pt={0} pb={1}>
            <hr className="mapDivider" />
          </Box>
        </Box>

        <Box py={5}>
          <Typography className="description" variant="h5" align="left">
            We would like to thank all Collaborators – you are simply brilliant!
            <br />
            <Box py={2}>
              <Typography className="description" variant="h5" align="center">
                <Link href="/team">
                  <Button variant="contained" size="large" color="primary" startIcon={<PeopleIcon />}>
                    For team members
                  </Button>
                </Link>
              </Typography>
            </Box>
            If you participated in our study – thank you for your time!
          </Typography>
        </Box>

        <Box py={5}>
          <Typography variant="h5" align="center">
            <img className="gif" src="/Final.gif" />
          </Typography>
        </Box>

        <Box py={5}>
          <Typography className="description" variant="h5" align="left">
            And if you want to stay attentive to the results of our project, leave a follow on{' '}
            <Link target="_blank" rel="noopener" href="https://twitter.com/Marta7Kowal">
              <img className="icon-bottom" src="twitter.png" width="30px" />
            </Link>
            , where I will post any updates. If you want to Collaborate with us on our future projects, please, let us know via email{' '}
            <Link target="_blank" rel="noopener" href="mailto:marta7kowal@gmail.com" noWrap>
              <img className="icon-bottom" src="gmail.png" width="30px" />
            </Link>
            .
          </Typography>
        </Box>

        <Box pb={5}>
          <Typography className="description" variant="h5" align="left">
            Have a great day,
          </Typography>
          <Typography className="description" variant="h5" align="left">
            Marta Kowal & Piotr Sorokowski
          </Typography>
          <br />
          <Typography className="description" variant="h5" align="left">
            This study is part of the grant entitled: Improving one’s physical attractiveness–sex and cross-cultural comparison (Preludium-17,
            financed by National Science Centre – Poland); Reg. No: 2019/33/N/HS6/00054.
          </Typography>
          <Box pt={3} display="flex" flexDirection="column" justifyContent="flex-start" align="left" id="media">
            <Box py={2}>
              <Typography variant="h5" className="sectionName underline">
                Published papers:
              </Typography>
              <Typography>
                <b>Kowal, M.,</b> Sorokowski, P., Pisanski, K., Valentova, J. V., Varella, M. A., Frederick, D. A., ... & Mišetić, K. (2022).
                Predictors of enhancing human physical attractiveness: Data from 93 countries. <i>Evolution and Human Behavior, 43</i>(6), 455-474.
                <br />
                <Link target="_blank" href="https://doi.org/10.1016/j.evolhumbehav.2022.08.003">
                  https://doi.org/10.1016/j.evolhumbehav.2022.08.003
                </Link>
              </Typography>

              <Typography>
                <b>Kowal, M.,</b> Bode, A., Koszałkowska K., Roberts, S.C., Gjoneska, B., Frederick, D., … & Sorokowski, P. (2024). Love as a
                commitment device: Evidence from a cross-cultural study across 90 countries. <i>Human Nature</i>.
                <br />
                <Link target="_blank" href="https://doi.org/10.1007/s12110-024-09482-6">
                  https://doi.org/10.1007/s12110-024-09482-6
                </Link>
              </Typography>

              <Typography>
                <b>Kowal, M.,</b> Sorokowski, P., Dinić, B. M., Pisanski, K., Gjoneska, B., Frederick, D., Pfuhl, G., Milfont, T. L., Bode, A.,
                Aguilar, L., García, F. E., Villaverde, B. A., Kavčič, T., Miroshnik, K. G., Ndukaihe, I. L. G., Šafárová, K., Valentova, J. V.,
                Aavik, T., Blackburn, A. M., … Sternberg, R. (2024). Validation of the Short Version (TLS-15) of the Triangular Love Scale (TLS-45)
                across 37 Languages. <i>Archives of Sexual Behavior</i>, 53, 839-857.
                <br />
                <Link target="_blank" href="https://doi.org/10.1007/s10508-023-02702-7">
                  https://doi.org/10.1007/s10508-023-02702-7
                </Link>
              </Typography>

              <Typography>
                Meskó, N., <b>Kowal, M.,</b> Láng, A., Kocsor, F., Bandi, S. A., Putz, A., ... & Han, H. (2024). Exploring attitudes toward “sugar
                relationships” across 87 countries: A global perspective on exchanges of resources for sex and companionship.{' '}
                <i>Archives of Sexual Behavior</i>, 53(2), 811-837.
                <br />
                <Link target="_blank" href="https://doi.org/10.1007/s10508-023-02724-1">
                  https://doi.org/10.1007/s10508-023-02724-1
                </Link>
              </Typography>
            </Box>

            <Box py={2}>
              <Typography variant="h5" className="sectionName underline">
                Submitted papers (hopefully soon to be published):
              </Typography>
              <Typography>
                <b>Kowal</b> et al. (2025). Cross-cultural data on romantic love, mate preferences, and physical attractiveness from 117,293
                participants across 175 countries.
              </Typography>
              <Typography>
                Grigoryev et al. (2025). Sociosexual Orientation, Life History Strategy, and Reproductive Success: A Large-Scale Cross-Cultural
                Analysis Across 92 Contemporary Societies.
              </Typography>
              <Typography>Zerhouni et al. (2025). Environmental harshness and sexual behavior: A multi-country comparison.</Typography>
              <Typography>
                <b>Kowal</b> et al. (2025). Insights on the importance of physical attractiveness: Two-step cluster analysis on data from 93
                countries.
              </Typography>
              <Typography>
                Gelbart et al. (2025). Culturally variable of culturally universal? Sexual and emotional jealousy across 37 countries.
              </Typography>
            </Box>

            <Box py={2}>
              <Typography variant="h5" className="sectionName underline">
                Conference talks:
              </Typography>

              <Typography>
                <b>Kowal, M.</b> (2024). Talk during the conference of the International Society for Human Ethology (ISHE), Arcata, USA{' '}
                <i>“The interplay between romantic love and culture and environment: Data from 61,065 individuals from 82 countries”</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2024). The PTNCE Award winner’s lecture at the 10th International Conference of the Polish Society for Human and
                Evolution Studies (PTNCE), University of Silesia in Katowice, Katowice, Poland.{' '}
                <i>“The pursuit of beauty: Understanding the who, why, and how intensely from a cross-cultural perspective”</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2024). Talk during the 2024 Big Team Science Conference (online),{' '}
                <i>
                  “Translation practices in cross-cultural social research and personal experiences in leading the translation process involving 45
                  languages”
                </i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2024). Talk during the EASP-IARR Joint symposium (online).{' '}
                <i>“The interplay between romantic love and gender equality: Data from 61,065 individuals from 82 countries”</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2024). Keynote speaker during the African Week (Cairo, Egypt, online){' '}
                <i>“Conducting large-scale cross-cultural studies: Insight from leading an international project”</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2024). Talk during the International Conference on Love Studies. Las Palmas de Gran Canaria, Spain.{' '}
                <i>“The interplay between romantic love and culture and environment: Data from 61,065 individuals from 82 countries”</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2023). Talk during the 2023 Big Team Science Conference (online).{' '}
                <i>
                  “How to find collaborators for cross-cultural studies? Insight from leading a project on physical attractiveness with 404 scholars”
                </i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2023). Talk during the Romantic and Companionate Love Research Symposium.{' '}
                <i>“How to Measure Romantic Love? Short Version (TLS-15) of the Triangular Love Scale (TLS-45)”</i>
              </Typography>

              <Typography>
                <b>Kowal, M.</b> (2022). Talk during the TLC Love Consortium conference, 2022, Durham, North Carolina, USA.{' '}
                <i>Validation of the shortened version (TLS-15) of the Triangular Love Scale (TLS-45) across 37 languages.</i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2022). Talk during the International Conference of the International Society for Human Ethology, 2022, University of
                Würzburg, Würzburg, Germany.{' '}
                <i>
                  Conducting large-scale cross-cultural studies: Insight from leading a project on physical attractiveness behaviors with 405 scholars
                  from 145 countries.
                </i>
              </Typography>
              <Typography>
                <b>Kowal, M.</b> (2021). Talk during the 7th International Conference of the Polish Society for Human and Evolution Studies,
                University of Bialystok, Poland.{' '}
                <i>A large-scale study on improving one’s physical attractiveness: Data from more than 50 countries.</i>
              </Typography>
            </Box>
            <Box py={2}>
              <Typography variant="h5" className="sectionName underline">
                Master theses:
              </Typography>
              <Typography>
                <b>Remešová, H.</b> (2022). <i>Vliv Náboženství a Dalších Faktorů na Kvalitu Partnerských Vztahů: Porovnání Zemí V4.</i> Master thesis
                defended at the Mendel University in Brno. Supervisor: Jiří Čeněk.
              </Typography>
              <Typography>
                <b>Hatlapatková, Z.</b> (2022).{' '}
                <i>
                  Mezinárodní Srovnání Determinant Výběru Partnera: Atraktivita a Preference Výběru Partnera V Zemích V4. Ideální Vs Reálný Partner.
                </i>{' '}
                Master thesis defended at the Mendel University in Brno. Supervisor: Jiří Čeněk.
              </Typography>
            </Box>
            <Box py={2}>
              <Typography variant="h5" className="sectionName underline">
                Media coverage:
              </Typography>
              <Typography>
                <b>Kowal, M. & Murphy, M.</b> (20202422). <i>Beauty Behavioral Ecology | Dr. Marta Kowal | Species Podcast</i>{' '}
                <Link href="https://www.youtube.com/watch?v=w9dEWaCbff4" target="_blank">
                  https://www.youtube.com/watch?v=w9dEWaCbff4
                </Link>
              </Typography>
              <Typography>
                <b>Kowal, M. & Al-Shawaf, L.</b> (2022). <i>Around the World, Who Spends Time on Their Looks and Why?</i>{' '}
                <Link
                  href="https://www.psychologytoday.com/us/blog/six-impossible-things-breakfast/202210/around-the-world-who-spends-time-their-looks-and-why"
                  target="_blank"
                >
                  Psychology Today
                </Link>
                . Posted October 25th, 2022.
              </Typography>
              <Typography>
                <b>Batres, C.</b> (2022). <i>Who Spends More Time Enhancing Their Attractiveness and Why.</i>{' '}
                <Link
                  href="https://www.psychologytoday.com/us/blog/lets-face-it/202209/who-spends-more-time-enhancing-their-attractiveness-and-why"
                  target="_blank"
                >
                  Psychology Today
                </Link>
                . Posted September 6th, 2022.
                <br />
              </Typography>
              <Typography>
                <b>Pírko, M.</b> (2021). Interview with Martin Pírko about the large-scale study with
                <Link
                  href="https://program.rozhlas.cz/zaznamy?fbclid=IwAR25T0H6g4LDGoT4ZUhROhIxek-NuDYAttueGIQ_g8JFT95XPfw0RNuTBRM#/brno/2021-06-16"
                  target="_blank"
                >
                  {' '}
                  Český rozhlas
                </Link>{' '}
                (16.6., 15.33).
              </Typography>
              <Typography>
                <b>
                  <Link href="https://www.eurekalert.org/news-releases/981197" target="_blank">
                    EurekAlert
                  </Link>
                </b>{' '}
                (2023). People spend 1/6th of their lifetime on enhancing their appearance. Posted February 28th, 2023.
              </Typography>
              <Typography>
                <b>Kowal, M. & Al-Shawaf, L. </b> (2023). Around the World, Who Spends Time on Their Looks and Why? A 93-country study with 93,000
                participants.{' '}
                <Link href="https://www.hbes.com/who-spends-time-on-their-looks/" target="_blank">
                  Human Behavior & Evolution Society.
                </Link>{' '}
                Posted March 6th, 2023.
              </Typography>
            </Box>
          </Box>
          <Box py={5}>
            <Grid container direction="row" alignItems="center">
              <Grid item md={3} xs={12}>
                <Link target="_blank" rel="noopener" href="https://scholar.google.com/citations?user=US0485YAAAAJ">
                  <img className="icon-bottom" src="Scholar.svg" width="30px" /> Scholar
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link target="_blank" rel="noopener" href="https://twitter.com/Marta7Kowal">
                  <img className="icon-bottom" src="twitter.png" width="30px" /> Twitter
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link target="_blank" rel="noopener" href="https://www.researchgate.net/profile/Marta_Kowal">
                  <img className="icon-bottom" src="researchgate.png" width="30px" /> Research Gate
                </Link>
              </Grid>
              <Grid item md={3} xs={12}>
                <Link target="_blank" rel="noopener" href="mailto:marta7kowal@gmail.com" noWrap>
                  <img className="icon-bottom" src="gmail.png" width="30px" /> marta7kowal@gmail.com{' '}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
